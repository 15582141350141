















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SettingsButton',
})
export default class Button extends Vue {
  @Prop({ default: '' }) color: string;
  @Prop({ default: '' }) value: string;
  @Prop({ default: false }) rounded: boolean;
  @Prop({ default: '' }) size: string;
  @Prop({ default: false }) outlined: boolean;
  @Prop({ default: '' }) icon: string;
}
